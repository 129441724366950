var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "header header--title"
  }, [_c('div', {
    staticClass: "header__container"
  }, [_c('button', {
    attrs: {
      "type": "button"
    },
    on: {
      "click": function click($event) {
        _vm.link ? _vm.$router.push({
          name: 'Dashboard'
        }) : _vm.$router.go(-1);
      }
    }
  }, [_vm._v(" 이전 ")]), _vm._t("title")], 2)]);
}
var staticRenderFns = []

export { render, staticRenderFns }