<template>
  <div class="header header--title">
    <div class="header__container">
      <button
        type="button"
        @click="link ? $router.push({name: 'Dashboard'}) : $router.go(-1)"
      >
        이전
      </button>
      <slot name="title"></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    link: {
      type: String
    }
  },
  data() {
    return {};
  }
};
</script>

<style lang="scss" scoped>
.header__container {
  display: flex;
}
.header--title {
  z-index: 1;
  border-bottom: none;

  h3 {
    position: relative;
    padding-left: 8px;
    line-height: $h;
    font-size: 16px;
    font-weight: 400;
    width: 100%;
  }
  button {
    width: 24px;
    height: $h;
    background-image: url($path + "bul_arr_left_gray.svg");
    background-repeat: no-repeat;
    background-position: center center;
    text-indent: -9999px;
  }
}
</style>
